
<template>
    <div>
        <request-data-table :isloading="isOutboxDatasLoading" :inbox="false" :dash="roottype" :headers="headers"
            :content="outboxDatas"></request-data-table>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import RequestDataTable from '@/views/dashboard/requests/components/RequestDataTable.vue'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        owner: {
            type: String,
            default: 'dashu'
        }
    },
    data() {
        return {
            isOutboxDatasLoading: true,
            outboxDatas: [],
            roottype: ((this.$route.path).substr(0, "/dashc".length).toUpperCase() == "/dashc".toUpperCase()) ? 'company' : 'user'
        }
    },
    components: {
        RequestDataTable
    },
    watch: {
        $route: {
            handler: function (newRouteValue) {
                let company = "/dashc"
                let professional = "/dashu"
                if ((this.$route.path).substr(0, company.length).toUpperCase() == company.toUpperCase()) {
                    this.roottype = 'company'
                }
                if ((this.$route.path).substr(0, professional.length).toUpperCase() == professional.toUpperCase()) {
                    this.roottype = 'user'
                }
            },
            deep: true
        }
    },

    beforeMount() {
        this.isOutboxDatasLoading = true
        if (this.owner === 'dashu') {
            Drequest.api(`lazyloading.rfcuser?dfilters=on&rfc.user_id:eq=${this.$store.getters.getUser.id}`)
                .get((response) => {
                    if (response.success) {
                        response.listEntity.forEach((value, index) => {
                            this.outboxDatas.push({
                                id: value.id,
                                status: value.status,
                                recipient: value.user.firstname,
                                owner: value.rfc.owner === "1" ? value.rfc.user.firstname : value.rfc.enterprise.name,
                                email: value.user.email,
                                user: String(1),
                                // user: String(value.rfc.owner),
                                request: value.rfc,
                                sent_at: value.sent_at
                            })
                        })
                    }
                })
                .catch((err) => {
                })

            Drequest.api(`lazyloading.rfcenterprise?dfilters=on&rfc.user_id:eq=${this.$store.getters.getUser.id}`)
                .get((response) => {
                    if (response.success) {
                        response.listEntity.forEach((value, index) => {
                            this.outboxDatas.push({
                                id: value.id,
                                status: value.status,
                                recipient: value.enterprise.name,
                                owner: value.rfc.owner === "1" ? value.rfc.user.firstname : value.rfc.enterprise.name,
                                email: value.enterprise.email,
                                user: String(0),
                                // user: String(value.rfc.owner),
                                request: value.rfc,
                                sent_at: value.sent_at
                            })
                        })
                        this.isOutboxDatasLoading = false
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Reload page..!",
                            timer: 3000
                        })
                        this.isOutboxDatasLoading = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.isOutboxDatasLoading = false
                })
        }
        if (this.owner === 'dashc') {
            Drequest.api(`lazyloading.rfcuser?dfilters=on&rfc.enterprise_id:eq=${this.$store.getters.getCompany.id}`)
            .get((response) => {
                if (response.success) {
                    response.listEntity.forEach((value, index) => {
                        this.outboxDatas.push({
                            id: value.id,
                            status: value.status,
                            recipient: value.user.firstname,
                            owner: value.rfc.owner === "1" ? value.rfc.user.firstname : value.rfc.enterprise.name,
                            email: value.user.email,
                            user: String(1),
                            // user: String(value.rfc.owner),
                            request: value.rfc,
                            sent_at: value.sent_at
                        })
                    })
                }
            })
            .catch((err) => {
            })
            Drequest.api(`lazyloading.rfcenterprise?dfilters=on&rfc.enterprise_id:eq=${this.$store.getters.getCompany.id}`)
                .get((response) => {
                    if (response.success) {
                        response.listEntity.forEach((value, index) => {
                            this.outboxDatas.push({
                                id: value.id,
                                status: value.status,
                                recipient: value.enterprise.name,
                                owner: value.rfc.owner === "1" ? value.rfc.user.firstname : value.rfc.enterprise.name,
                                email: value.enterprise.email,
                                user: String(0),
                                request: value.rfc,
                                sent_at: value.sent_at
                            })
                        })
                        this.isOutboxDatasLoading = false
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Reload page..!",
                            timer: 3000
                        })
                        this.isOutboxDatasLoading = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.isOutboxDatasLoading = false
                })
        }
    },
    setup() {
        const headers = [
            {
                text: 'Type',
                align: 'start',
                sortable: true,
                value: 'type'
            },
            {
                text: 'To',
                align: 'start',
                sortable: true,
                value: 'ruser'
            },
            {
                text: 'Object',
                align: 'start',
                sortable: true,
                value: 'object'
            },
            {
                text: 'Status',
                align: 'start',
                sortable: true,
                value: 'status'
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        return {
            headers,
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
